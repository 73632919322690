import {
  CellId,
  GridElementEntityId,
  StaticCellId,
  sleep,
  uuid,
} from "@hex/common";
import { isEqual } from "lodash";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { ScrollBehavior } from "scroll-into-view-if-needed/typings/types.js";

import { customScrollIntoView } from "../../util/customScrollIntoView.js";

export interface CellScrollAPIOptions {
  /**
   * Determines which part of a cell to scroll to in the logic view
   */
  scrollTarget?:
    | "source"
    | "output"
    | { type: "lineNumber"; lineNumber: number };
  /**
   * What kind of scroll to do, @see scroll-into-view-if-needed
   */
  scrollBehavior?: ScrollBehavior;
  /**
   * Wait until all cells have finished adjusting in size for layout before scrolling.
   * Useful for avoiding uneeded jumps when initially loading.
   */
  waitForSizeChanges?: boolean;
}

/**
 * The mechanism for scrolling to a cell changes depending on virtualization
 * so we dynamically swap out the implementation. We also support scrolling
 * in multiple locations as well.
 */
export interface CellScrollApi {
  scrollToCellById?: (cellId: CellId, options?: CellScrollAPIOptions) => void;
  scrollToCellByStaticId?: (
    staticCellId: StaticCellId,
    options?: CellScrollAPIOptions,
  ) => void;
}

class CellScrollApiWrapper {
  private apis: Record<string, CellScrollApi> = {};

  public addApi(api: CellScrollApi): { removeApi: () => void } {
    const id = uuid();
    this.apis[id] = api;
    const removeApi = (): void => {
      delete this.apis[id];
    };
    return { removeApi };
  }

  public scrollToCellById(
    cellId: CellId,
    options?: CellScrollAPIOptions,
  ): void {
    for (const api of Object.values(this.apis)) {
      api.scrollToCellById?.(cellId, options);
    }
  }

  public scrollToCellByStaticId(
    staticCellId: StaticCellId,
    options?: CellScrollAPIOptions,
  ): void {
    for (const api of Object.values(this.apis)) {
      api.scrollToCellByStaticId?.(staticCellId, options);
    }
  }
}

const CellScrollApiContext =
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- for dev display name
  createContext<CellScrollApiWrapper | undefined>(undefined);
CellScrollApiContext.displayName = "CellScrollApiContext";

/**
 * Root context for regiestering / calling different cell scroll APIs.
 * Needs to be added to the component tree at least once.
 *
 * Useful to add again if trying to completly override scroll behaviour for a sub tree.
 */
export const CellScrollApiContextProvider: React.ComponentType<{
  children: ReactNode;
}> = ({ children }) => {
  const [apiWrapper] = useState(() => new CellScrollApiWrapper());

  return (
    <CellScrollApiContext.Provider value={apiWrapper}>
      {children}
    </CellScrollApiContext.Provider>
  );
};

/**
 * Add an additional cell scroll api to the context.
 *
 * Add api instances are called when `scrollToCellById` is invoked on the wrapper.
 * This allows for scrolling in multiple views concurrently using different methods.
 *
 * @returns a remove callback that should be called to prevent memory leaks
 */
export const useAddCellScrollApi = (): CellScrollApiWrapper["addApi"] => {
  const wrapper = useContext(CellScrollApiContext);
  return useCallback(
    (newApi) => {
      if (wrapper == null) {
        console.warn(
          "Cell scroll api context not found, make sure a provider was added",
        );
        return { removeApi: () => {} };
      } else {
        return wrapper.addApi(newApi);
      }
    },
    [wrapper],
  );
};

/**
 * Scroll to a given cell.
 *
 * Uses all registered scroll api's to allow for scrolling in multiple views concurrently.
 * Will correctly use virtualization scroll methods if needed.
 */
export const useScrollToCell = (): CellScrollApiWrapper["scrollToCellById"] => {
  const wrapper = useContext(CellScrollApiContext);
  return useCallback(
    (cellId, options) => {
      if (wrapper == null) {
        console.warn(
          "Cell scroll api context not found, make sure a provider was added",
        );
      } else {
        wrapper.scrollToCellById(cellId, options);
      }
    },
    [wrapper],
  );
};

/**
 * Scroll to a given cell by static id.
 *
 * Uses all registered scroll api's to allow for scrolling in multiple views concurrently.
 * Will correctly use virtualization scroll methods if needed.
 */
export const useScrollToCellByStaticId =
  (): CellScrollApiWrapper["scrollToCellByStaticId"] => {
    const wrapper = useContext(CellScrollApiContext);
    return useCallback(
      (staticCellId, options) => {
        if (wrapper == null) {
          console.warn(
            "Cell scroll api context not found, make sure a provider was added",
          );
        } else {
          wrapper.scrollToCellByStaticId(staticCellId, options);
        }
      },
      [wrapper],
    );
  };

/**
 * Cell Scroll Utils
 */

/**
 * Get a stable DOM id to an anchor tag associated with a cell
 */
export function getCellAnchorId(cellId: CellId): string {
  return `cellAnchor-${cellId}`;
}

/**
 * Get a stable DOM id to an anchor tag associated with a cell in diff view
 */
export function getDiffCellAnchorId(staticCellId: StaticCellId): string {
  return `cellDiffAnchor-${staticCellId}`;
}

/**
 * Get a stable DOM id to an anchor tag associated with an app element
 */
export function getAppElementAnchorId(entityId: GridElementEntityId): string {
  return `appCellAnchor-${entityId}`;
}

/**
 * Get a stable DOM id to a scroll container for cells
 */
export function getCellScrollParentId(
  location: "app" | "logic" | "diff",
): string {
  return `cellScrollParent-${location}`;
}

const checkScrollHeight = (location: "app" | "logic" | "diff"): number[] => {
  const eles = document.querySelectorAll(getCellScrollParentId(location));
  const heights = Array.from(eles).map((ele) => ele.scrollHeight);

  return heights;
};

const maybeDelayScroll = async ({
  delay,
  location,
}: {
  delay: boolean;
  location: "app" | "logic" | "diff";
}): Promise<void> => {
  if (!delay) {
    return;
  }

  const ITERATION_DELAY = 500;
  const NUM_ITERATIONS = 3;

  // If the scroll height of any cell scroll container is changing, don't start the scroll yet.
  let currentHeight = checkScrollHeight(location);
  for (let i = 0; i < NUM_ITERATIONS; ++i) {
    // eslint-disable-next-line no-await-in-loop -- we want to iteratively wait in this case
    await sleep(ITERATION_DELAY);
    const newHeight = checkScrollHeight(location);
    if (isEqual(currentHeight, newHeight)) {
      break;
    }
    currentHeight = newHeight;
  }
};

/**
 * Offset is so we don't cut off the top of the selected cell outline
 */
export const CELL_OUTLINE_OFFSET = 10;

/**
 * Offset the scroll position so the targeted line is in view
 * Each line in the monaco editor is 18px tall, so approximate the offset
 */
export const getMonacoLineNumberPxScrollOffset = (
  lineNumber: number,
): number => {
  return lineNumber * 18 - CELL_OUTLINE_OFFSET;
};

/**
 * You most likely want to use `useScrollToCell` over this to properly account for virtualization
 *
 * Scrolls to a cell in the logic view using conventional DOM methods
 */
export const scrollToLogicCellNonVirtualzed = ({
  cellId,
  onFinished,
  options: {
    scrollBehavior = "smooth",
    scrollTarget = "source",
    waitForSizeChanges,
  },
}: {
  cellId: CellId;
  options: CellScrollAPIOptions;
  onFinished?: () => void;
}): void => {
  void maybeDelayScroll({
    delay: waitForSizeChanges === true,
    location: "logic",
  }).then(() => {
    const cellAnchorElement = document.getElementById(getCellAnchorId(cellId));

    if (cellAnchorElement) {
      if (scrollTarget === "output" || scrollTarget === "source") {
        customScrollIntoView(
          cellAnchorElement,
          {
            scrollMode: "if-needed",
            behavior: scrollBehavior,
            block: scrollTarget === "output" ? "end" : "center",
            offsetTop: -CELL_OUTLINE_OFFSET,
          },
          onFinished,
        );
      } else {
        customScrollIntoView(
          cellAnchorElement,
          {
            scrollMode: "always",
            behavior: scrollBehavior,
            block: "start",
            offsetTop: getMonacoLineNumberPxScrollOffset(
              scrollTarget.lineNumber,
            ),
          },
          onFinished,
        );
      }
    }
  });
};

/**
 * Scrolls to a cell in a diff view using conventional DOM methods
 */
export const scrollToDiffCell = ({
  options: { scrollBehavior = "smooth", waitForSizeChanges },
  staticCellId,
}: {
  staticCellId: StaticCellId;
  options: CellScrollAPIOptions;
}): void => {
  void maybeDelayScroll({
    delay: waitForSizeChanges === true,
    location: "diff",
  }).then(() => {
    const cellAnchorElement = document.getElementById(
      getDiffCellAnchorId(staticCellId),
    );
    if (cellAnchorElement) {
      customScrollIntoView(cellAnchorElement, {
        scrollMode: "if-needed",
        behavior: scrollBehavior,
        block: "center",
      });
    }
  });
};

/**
 * Scrolls to an app element conventional DOM methods
 */
export const scrollToAppElement = ({
  entityId,
  options: { scrollBehavior = "smooth", waitForSizeChanges },
}: {
  entityId: GridElementEntityId;
  options: {
    scrollBehavior?: ScrollBehavior;
    waitForSizeChanges?: boolean;
  };
}): void => {
  void maybeDelayScroll({
    delay: waitForSizeChanges === true,
    location: "app",
  }).then(() => {
    const appAnchorElement = document.getElementById(
      getAppElementAnchorId(entityId),
    );
    if (appAnchorElement) {
      customScrollIntoView(appAnchorElement, {
        scrollMode: "if-needed",
        behavior: scrollBehavior,
        block: "center",
        offsetTop: -CELL_OUTLINE_OFFSET,
      });
    }
  });
};
