import { ParameterName, notEmpty } from "@hex/common";
import { useCallback } from "react";

import { useCellsContentsGetter } from "../../hex-version-multiplayer/state-hooks/cellsContentsStateHooks";

export function useGetUniqueInputCellName(): () => ParameterName {
  const getCellsContents = useCellsContentsGetter();

  return useCallback(() => {
    const inputCells = Object.values(getCellsContents())
      .filter(notEmpty)
      .flatMap((c) => (c.__typename === "Parameter" ? c : []));

    const existingInputNames = new Set(
      inputCells.map((inputCell) => inputCell.name),
    );
    let count = 1;
    let testName = `input_${count}`;
    while (existingInputNames.has(testName as ParameterName)) {
      count++;
      testName = `input_${count}`;
    }

    return testName as ParameterName;
  }, [getCellsContents]);
}
