import {
  CellId,
  GridElementEntityId,
  HexVersionId,
  LayoutType,
  SharedFilterId,
  assertNever,
  guardNever,
  stableEmptyArray,
  stableEmptyObject,
} from "@hex/common";

import {
  CanvasElementMpFragment,
  StoryElementMpFragment,
} from "../../hex-version-multiplayer/HexVersionMPModel.generated.js";
import { useSelector } from "../../redux/hooks.js";
import { hexVersionMPSelectors } from "../../redux/slices/hexVersionMPSlice.js";
import { RootState } from "../../redux/store.js";
import { useProjectContext } from "../../util/projectContext.js";

export function useEntitiesAddedToApp(args: {
  cellIds: CellId[];
}): Record<CellId, boolean> {
  const { hexVersionId } = useProjectContext();
  const { cellIds } = args;
  return useSelector((state) => {
    const layoutType = hexVersionMPSelectors
      .getHexVersionSelectors(hexVersionId)
      .select(state).layoutType;

    const cellIdToInApp = stableEmptyObject<CellId, boolean>();
    if (layoutType == null) {
      return cellIdToInApp;
    }
    switch (layoutType) {
      case LayoutType.STORY: {
        const storyElements =
          hexVersionMPSelectors
            .getStoryElementSelectors(hexVersionId)
            .selectAll(state) ?? stableEmptyArray<StoryElementMpFragment>();
        cellIds.forEach((cellId) => {
          cellIdToInApp[cellId] = storyElements?.some(
            (el) =>
              el.cell.id === cellId &&
              isEntityInStoryLayout(state, hexVersionId, cellId),
          );
        });
        return cellIdToInApp;
      }
      case LayoutType.CANVAS: {
        const canvasElements =
          hexVersionMPSelectors
            .getCanvasElementSelectors(hexVersionId)
            .selectAll(state) ?? stableEmptyArray<CanvasElementMpFragment>();
        cellIds.forEach((cellId) => {
          cellIdToInApp[cellId] = canvasElements?.some(
            (el) =>
              el.cell.id === cellId &&
              isEntityInCanvasLayout(state, hexVersionId, cellId),
          );
        });
        return cellIdToInApp;
      }
      case LayoutType.GRID: {
        cellIds.forEach((cellId) => {
          cellIdToInApp[cellId] = isEntityInGridLayout(
            state,
            hexVersionId,
            cellId,
          );
        });
        return cellIdToInApp;
      }
      default:
        guardNever(layoutType, layoutType);
    }
    return cellIdToInApp;
  });
}

export function useEntityAddedToApp(
  args: { cellId: CellId } | { sharedFilterId: SharedFilterId },
): boolean {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const cellId = "cellId" in args ? args.cellId : null;
    const sharedFilterId =
      "sharedFilterId" in args ? args.sharedFilterId : null;

    if (cellId != null && sharedFilterId != null) {
      console.warn(
        "Received both cellId and sharedFilterId, but expected only one. Using cell Id.",
      );
    }

    const layoutType = hexVersionMPSelectors
      .getHexVersionSelectors(hexVersionId)
      .select(state).layoutType;

    if (cellId != null) {
      switch (layoutType) {
        case null:
        case undefined:
          return false;
        case LayoutType.STORY: {
          return isEntityInStoryLayout(state, hexVersionId, cellId);
        }
        case LayoutType.CANVAS: {
          return isEntityInCanvasLayout(state, hexVersionId, cellId);
        }
        case LayoutType.GRID: {
          return isEntityInGridLayout(state, hexVersionId, cellId);
        }
        default:
          assertNever(layoutType, layoutType);
      }
    } else if (sharedFilterId != null) {
      // shared filter is ONLY supported in grid, so no need to check other layouts
      return isEntityInGridLayout(state, hexVersionId, sharedFilterId);
    } else {
      return false;
    }
  });
}

function isEntityInStoryLayout(
  state: RootState,
  hexVersionId: HexVersionId,
  cellId: CellId,
): boolean {
  const storyElement = hexVersionMPSelectors
    .getStoryElementSelectors(hexVersionId)
    .selectByCellId(state, cellId);
  return (
    storyElement != null &&
    storyElement.visible &&
    storyElement.deletedDate == null
  );
}

function isEntityInCanvasLayout(
  state: RootState,
  hexVersionId: HexVersionId,
  cellId: CellId,
): boolean {
  const canvasElements = hexVersionMPSelectors
    .getCanvasElementSelectors(hexVersionId)
    .selectCellIdToCanvasElements(state)[cellId];
  return (
    canvasElements != null &&
    canvasElements.length > 0 &&
    canvasElements.some((el) => el.deletedDate == null)
  );
}

function isEntityInGridLayout(
  state: RootState,
  hexVersionId: HexVersionId,
  entityId: GridElementEntityId,
): boolean {
  const gridElements = hexVersionMPSelectors
    .getGridRowSelectors(hexVersionId)
    .selectEntityIdToGridElements(state)[entityId];
  return (
    gridElements != null &&
    gridElements.length > 0 &&
    gridElements.some((el) => el.deletedDate == null)
  );
}
