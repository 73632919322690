import {
  CellId,
  FRACTIONAL_INDEX_END,
  FRACTIONAL_INDEX_START,
  StaticCellId,
  fractionalIndexMidpoint95,
  notEmpty,
} from "@hex/common";
import { useCallback } from "react";
import { createSelector } from "reselect";

import { useCellsGetter } from "../../hex-version-multiplayer/state-hooks/cellsStateHooks";
import { CellMP } from "../../redux/slices/hexVersionMPSlice";
import { SortableCell, getSortedCells } from "../../util/cellLayoutHelpers";

export const selectSortedCells = createSelector(
  (cellsState: Record<CellId, CellMP | undefined>) => cellsState,
  (cellsState) => getSortedCells(Object.values(cellsState).filter(notEmpty)),
);

export const makeSelectCellIndex = (): ((
  cellsState: Record<CellId, CellMP | undefined>,
  staticCellId: StaticCellId,
) => number) =>
  createSelector(
    selectSortedCells,
    (_: Record<CellId, CellMP | undefined>, staticCellId: StaticCellId) =>
      staticCellId,
    (sortedCells, staticCellId) =>
      sortedCells.findIndex((c) => c.staticId === staticCellId),
  );

type SafeCellOrders<S extends boolean> = S extends false
  ? readonly SortableCell[]
  : readonly SortableCell[] | undefined;

export type UseCellOrdersGetterArgs<SAFE extends boolean> = {
  /**
   * @default false
   */
  safe?: SAFE;
  /** Whether to ignore component children cells or not*/
  ignoreComponentChildCells?: boolean;
  /** Whether to ignore block children cells or not */
  ignoreBlockChildCells?: boolean;
};

export type UseCellOrdersGetterResult<SAFE extends boolean> =
  () => SafeCellOrders<SAFE>;

export function useCellOrdersGetter<SAFE extends boolean = false>(
  args: UseCellOrdersGetterArgs<SAFE> = {},
): UseCellOrdersGetterResult<SAFE> {
  const cellsSelector = useCallback(
    (cells: Record<CellId, CellMP | undefined> | undefined) => {
      if (cells === undefined && !args.safe) {
        throw new Error("missing cells state");
      } else if (cells === undefined) {
        return undefined;
      }

      let resolvedCells = Object.values(cells).filter(notEmpty);

      if (args.ignoreComponentChildCells || args.ignoreBlockChildCells) {
        resolvedCells = resolvedCells.filter(
          (c) =>
            (!args.ignoreBlockChildCells || c.parentBlockCellId == null) &&
            (!args.ignoreComponentChildCells ||
              c.parentComponentImportCellId == null),
        );
      }
      return getSortedCells(resolvedCells);
    },
    [args.safe, args.ignoreComponentChildCells, args.ignoreBlockChildCells],
  );

  return useCellsGetter({
    selector: cellsSelector,
    safe: args.safe ?? false,
  }) as () => SafeCellOrders<SAFE>;
}

export function useGetOrderAfter() {
  const getOrders = useCellOrdersGetter({
    ignoreComponentChildCells: true,
  });

  return useCallback(
    (cellId?: CellId) => {
      const orders = getOrders();
      let idx = orders.findIndex((o) => o.id === cellId);
      if (idx < 0) idx = orders.length - 1;
      const afterOrder = orders[idx].order;
      const beforeOrder = orders[idx + 1]?.order;

      return fractionalIndexMidpoint95(
        afterOrder ?? FRACTIONAL_INDEX_START,
        beforeOrder ?? FRACTIONAL_INDEX_END,
      );
    },
    [getOrders],
  );
}
export function useGetOrderBefore() {
  const getOrders = useCellOrdersGetter({
    ignoreComponentChildCells: true,
  });

  return useCallback(
    (cellId?: CellId) => {
      const orders = getOrders();
      let idx = orders.findIndex((o) => o.id === cellId);
      if (idx < 0) idx = orders.length - 1;
      const afterOrder = orders[idx].order;
      const beforeOrder = orders[idx - 1]?.order;

      return fractionalIndexMidpoint95(
        beforeOrder ?? FRACTIONAL_INDEX_START,
        afterOrder ?? FRACTIONAL_INDEX_END,
      );
    },
    [getOrders],
  );
}

export function useGetCellBeforeOrder() {
  const getOrderedCells = useCellOrdersGetter({
    ignoreComponentChildCells: true,
  });

  return useCallback(
    (order: string): SortableCell | undefined => {
      const sortedCells = getOrderedCells();
      let cellToReturn: SortableCell | undefined;
      sortedCells.forEach((cell, idx) => {
        const nextCell = sortedCells[idx + 1];

        if (cell.order < order && (!nextCell || nextCell.order >= order)) {
          cellToReturn = cell;
        }
      });
      return cellToReturn;
    },
    [getOrderedCells],
  );
}

export function useGetCellAfterOrder() {
  const getOrderedCells = useCellOrdersGetter({
    ignoreComponentChildCells: true,
  });

  return useCallback(
    (order: string): SortableCell | undefined => {
      const sortedCells = getOrderedCells();
      return sortedCells.find((cell) => cell.order > order);
    },
    [getOrderedCells],
  );
}
